/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.1.0): index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Alert from '~/alert'
import Button from '~/button'
import Carousel from '~/carousel'
import Collapse from '~/collapse'
import Dropdown from '~/dropdown'
import Modal from '~/modal'
import Offcanvas from '~/offcanvas'
import Popover from '~/popover'
import ScrollSpy from '~/scrollspy'
import Tab from '~/tab'
import Toast from '~/toast'
import Tooltip from '~/tooltip'

export default {
  Alert,
  Button,
  Carousel,
  Collapse,
  Dropdown,
  Modal,
  Offcanvas,
  Popover,
  ScrollSpy,
  Tab,
  Toast,
  Tooltip
}
